<template>
  <b-sidebar
    id="sidebar"
    v-model="isVisible"
    right
    width="100%"
    @shown="disableBackgroundScroll"
  >
    <div class="w-100 bg-white">
      <b-row class="fixed-top p-1 bg-white box-shadow-bottom">
        <b-col cols="8">
          <h2><b-badge variant="primary">
            {{ selectedCurrencName }}
          </b-badge></h2>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-end"
        ><feather-icon
          icon="XIcon"
          size="25"
          class="cursor-pointer"
          color="blue"
          @click="closeSidebar"
        /></b-col>

      </b-row>

      <b-container
        class="sidebar-content pt-5 w-100"
      >
        <!-- {{ selectedCurrencData }} -->
        <div
          v-for="(item , index) in selectedCurrencData"
          :key="index"
          class="account-item"
        >
          <p v-if="item.office_name">
            <strong class="text-primary">Office : </strong>  {{ item.office_name }}
          </p>
          <p v-if="item.account_name">
            <strong class="text-primary">Account : </strong>  <strong /> {{ item.account_name }}
          </p>
          <p v-if="item.account_number">
            <strong class="text-primary">Number : </strong>{{ item.account_number }}
          </p>
          <p v-if="item.account_type">
            <strong class="text-primary">Type : </strong> {{ item.account_type }}
          </p>
          <p v-if="item.running_balance">
            <strong class="text-primary">Running Balance : </strong>  {{ item.running_balance }}
          </p>
        </div>

      </b-container>
    </div>
  </b-sidebar>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    FeatherIcon,
  },
  props: {
    selectedCurrencData: {
      type: Array,
      default: () => ([]),
    },
    selectedCurrencName: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.modelValue,
    }
  },
  watch: {
    modelValue(newValue) {
      this.isVisible = newValue
    },
  },
  methods: {
    closeSidebar() {
      this.isVisible = false
      this.$emit('modelValue', this.isVisible)
      document.body.style.overflow = ''
    },
    disableBackgroundScroll() {
      document.body.style.overflow = 'hidden'
    },
  },
}
</script>
  <style>
  .box-shadow-bottom{
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
  }
  .b-sidebar-body{
    background-color: white;
  }
  .sidebar-content {
    overflow-y: auto;
  }
  .account-item{
    border: 1px solid #ccc;
    padding:20px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  </style>
