<template>
  <div>
    <div v-if="!loader">
      <div class="row justify-content-around">
        <b-card
          v-for="(currency, index) in currencyData"
          :key="index"
          :style="{ border: `1px solid ${gradientColors[index % gradientColors.length]}`}"
          style="width: 48%;"
          class="text-center"
          :data-aos="'fade-down'"
          data-aos-easing="linear"
          data-aos-duration="400"
          :data-aos-delay="`${index * 50}`"
          @click="openSidebar(currency.accounts , currency.currency)"
        >
          <h2 :style="{ color: `${gradientColors[index % gradientColors.length]}`}">
            {{ currency.currency }}
          </h2>
          <h5 class="text-secondary">
            {{ currency.total_balance.toFixed(2) }}
          </h5>

        </b-card>
      </div>
      <CurrencySideBar
        :model-value="isSidebarOpen"
        :selected-currenc-data="selectedCurrencData"
        :selected-currenc-name="selectedCurrencName"
        @modelValue="isSidebarOpen = false"
      />
    </div>
    <div
      v-else
      class="text-center pt-5"
    >
      <h3
        class="text-primary"
        style="font-weight: bold; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;"
      >
        Loading . . .
      </h3>
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem; margin-top: 2rem;"
        label="Large Spinner"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AOS from 'aos'
import decryptData from '@/store/auth/decryptData'
import CurrencySideBar from './side-right-bar/CurrencySideBar.vue'

export default {
  components: {
    CurrencySideBar,
  },
  data() {
    return {
      loader: false,
      isSidebarOpen: false,
      optionsCurrency: [
        'AED',
        'USD',
        'EGP',
        'SAR',
        'EUR',
        'KWD',
        'TRY',
        'PKR',
        'INR',
        'PHP',
        'QAR',
      ],
      currencyData: [],
      selectedCurrencData: [],
      selectedCurrencName: '',
      gradientColors: [
        '#ff7e5f',
        '#6a11cb',
        '#11998e',
        '#ff0000',
        '#fbc02d',
        '#616161',
        '#1976d2',
        '#ff6666',
        '#ffd54f',
        '#bdbdbd',
        '#64b5f6',
      ],
    }
  },
  async mounted() {
    this.getAllData()
    AOS.init({ once: false })
  },

  methods: {
    async getAllData() {
      this.loader = true
      try {
        const currencyRequests = this.optionsCurrency.map(item => axios.get(`zoho/bank-accounts/${item}`))

        const currencyResponses = await Promise.all(currencyRequests)

        this.currencyData = currencyResponses.map(response => decryptData(response.data.payload).data.data)
      } catch (error) {
        if (error) {
          this.$toast({
            props: {
              title: 'Error fetching data',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.loader = false
      }
    },
    openSidebar(data, name) {
      this.selectedCurrencData = data
      this.selectedCurrencName = name
      this.isSidebarOpen = true
    },
  },
}
</script>
<style>

</style>
